const COVID_19 = {
    id: "covid-19",
    title: "COVID-19",
    description: `COVID-19 originated in Wuhan, China. This virus has travelled around the world and has been declared by The World Health Organization as a worldwide pandemic. Over 2 years now, nearly 300 million positive cases have been detected with over 5 million deaths. Many countries have been heavily affected, including America, Italy and Spain.
        Coronavirus is a family of viruses that can affect humans and animals. They’re structure is a sphere with spikes of protein surrounding it to infect healthy cells. These spikes also allow the body to acknowledge its presence which then causes the symptoms to show. Because the form of this virus replicated a crown, it is named "corona" meaning crown in Latin. It's variants are named using the Greek alphabet. The most recent and pervasive variant is Omicron.`,
    imageUrl: require('./covid-19.jpg'),
    links: [
        {
            name: 'Canada Helps',
            url: 'https://www.canadahelps.org/en/donate-to-coronavirus-outbreak-response/',
        },
        {
            name: 'Food Banks Canada',
            url: 'https://www.foodbankscanada.ca/COVID-19.aspx',
        },
        {
            name: 'Covid-19 Toronto',
            url: 'https://www.toronto.ca/home/covid-19/covid-19-how-you-can-help/covid-19-donateto/',
        },
        {
            name: 'World Health Organization',
            url: 'https://www.who.int/emergencies/diseases/novel-coronavirus-2019/donate#:~:text=Everyone%20can%20now%20support%20directly,.COVID19ResponseFund.org.',
        },
        {
            name: 'Canadian Red Cross',
            url: 'https://www.redcross.ca/how-we-help/current-emergency-responses/covid-19-%E2%80%93-novel-coronavirus',
        },
        {
            name: 'Alberta Health Services',
            url: 'https://www.albertahealthservices.ca/give/give.aspx',
        },
        {
            name: 'Howard Brown',
            url: 'https://howardbrown.org/get-involved/support-our-covid-19-response/',
        },
        {
            name: 'Matthew 25: Ministries',
            url: 'https://donatenow.networkforgood.org/matthew25ministries',
        },
        {
            name: 'Fistula Foundation',
            url: 'https://fistulafoundation.org/donations/covid-19-emergency-fund/',
        }
    ]
};

const BLACK_LIVES_MATTER = {
    id: "blm",
    title: "Black Lives Matter",
    description: "On May 25 2020, a black man named George Floyd was murdered in Minnesota at the hands of a white cop. Once this   tragic event occurred, many people around the world set up protests to voice their anger with the issue of racism that has    yet to be solved after hundreds of years. Of course during the pandemic large gatherings were discouraged, however, personal protective equipment was worn for   those who attended. For the rest of the month of May and almost all of June, hashtags and posts were trending to spread       awareness of the terrible acts of racism in the last 20 years. Currently, online petitions and donations are the most impactful way to help make change and to encourage local authorities to sentence the police who kill black people without reason.",
    imageUrl: require('./blm.jpg'),
    links: [
        {
            name: 'BLM Canada',
            url: 'https://secure3.convio.net/blmca/site/Donation2;jsessionid=00000000.app30028b?df_id=1480&mfc_pref=T&1480.donation=form1&FR_ID=1060&mfc_pref=T&PROXY_ID=1060&PROXY_TYPE=21/&NONCE_TOKEN=99797F29668989D831EE516645126FAB',
        },
        {
            name: 'Carrd',
            url: 'https://blacklivesmatters.carrd.co/#donate',
        },
        {
            name: 'Act Blue',
            url: 'https://secure.actblue.com/donate/ms_blm_homepage_2019',
        },
        {
            name: 'Equal Justice Initiative',
            url: 'https://support.eji.org/give/153413/#!/donation/checkout',
        },
        {
            name: 'ACLU',
            url: 'https://action.aclu.org/give/now',
        },
        {
            name: '174 Ways',
            url: 'https://nymag.com/strategist/article/where-to-donate-for-black-lives-matter.html',
        },
        {
            name: 'Ways to Help',
            url: 'https://blacklivesmatters.carrd.co/',
        }
    ]

};

const PALESTINE_HUMAN_RIGHTS = {
    id: "palestine-human-rights",
    title: "Palestine Crisis",
    description: "The human rights in Palestine has been greatly restricted due to the Israeli government's acts of abolishing goods as well as people in and out of the Gaza Strip and moving Israeli citizens into the Western area, which is considered illegal by the international humanitarian law. The Gaza Strip, or simply known as Gaza, is a place in Palestine territory that is self governed and populated by over 2 million Palestine people. It is located just east of the Mediterranean sea. Many homes were destroyed as well as many people killed.",
    imageUrl: require('./palestine.jpg'),
    links: [
        {
            name: 'Islamic Relief Canada',
            url: 'https://www.islamicreliefcanada.org/appeals/palestine/',
        },
        {
            name: 'Canada Helps',
            url: 'https://www.canadahelps.org/en/charities/medical-aid-for-palestine/',
        },
        {
            name: 'Islamic Aid',
            url: 'https://www.islamicaid.com/palestine-apeal/',
        },
        {
            name: 'ICNA Relief Canada',
            url: 'https://icnareliefcanada.ca/news?id=10',
        },
        {
            name: 'PCRF',
            url: 'https://www.pcrf.net/',
        },
        {
            name: 'anera',
            url: 'https://www.anera.org/where-we-work/gaza/',
        }
    ]
};

const TURKEY_GENDER_VIOLENCE = {
    id: "turkey-gender-violence",
    title: "Turkey Gender Violence",
    description: "You may have noticed at some point in 2020 that many women posted black and white photos of themselves on social media platforms with the hashtag “challenge accepted”. This challenge was called upon by the women of Turkey to raise awareness of gender based violence and has been in action since 2016. The incident that flourished this trend again was the murder of Pinar Gültekin who was brutally killed by her ex-boyfriend. In Turkey, femicide has been a rising problem with an increasing number of women murdered. The significance of the challenge photos being monochrome is to imitate the pictures of women in the newspaper that have been killed.",
    imageUrl: require('./turkey-violence.jpg'),
    links: [
        {
            name: 'Mor Çatı',
            url: 'https://morcati.org.tr/bagis/',
        },
        {
            name: 'GO Fund Me',
            url: 'https://ca.gofundme.com/f/domestic-violence-against-women-in-turkey',
        },
        {
            name: 'Small Projects Istanbul',
            url: 'https://www.smallprojectsistanbul.org/donate/',
        }
    ]
};

const UYGHUR_MUSLIMS = {
    id: "uyghur-muslims-in-china",
    title: "Uyghur Muslims in China",
    description: "In over 85 concentration camps, located in Xinjiang, China, more than a million Uighur Muslims. The Xinjiang re-education camps, officially called Vocational Education and Training Centers by the Government of China, are internment camps operated by the Xinjiang Uygur Autonomous Region government and its CCP committee.In total ASPI identified 380 detention centres established across the region since 2017, ranging from lowest security re-education camps to fortified prisons.",
    imageUrl: require('./uyghur-protest.jpg'),
    links: [
        {
            name: 'ICNA Relief Canada',
            url: 'https://donate.icnareliefcanada.ca/donation/international?project=Uyghur+Relief',
        },
        {
            name: 'Sound Vision',
            url: 'https://give.soundvision.com/civicrm/contribute/transact?id=3&#038;reset=1',
        }
    ]
};


const TALIBAN_IN_AFGHANISTAN = {
    id: "taliban-in-afghanistan",
    title: "Taliban in Afghanistan",
    description: "The Taliban is an Islamic fundamentalist group that recently re-gained their power in Afghanistan in 2021. Recently, they have been taking similar action to what they had done in the 1990s which was, among many things, to detain protestors, abuse journalists, enforce harsh rules and living conditions, and prevent women from having proper education or basic human rights. Poverty has become more and more of an issue since their quality of life has deteriorated on a large scale in a short span of time. Currently, Afghans live through harsh conditions having to live with constant change, fearing for their and their families life, making efforts to flee their homes for their safety, and doing everything in their power to avoid contact with the Taliban.",
    imageUrl: require('./taliban-afghanistan.jpg'),
    links: [
        {
            name: 'Charity links are currently being updated',
        }
    ]
       
};


const MENTAL_HEALTH_CRISIS = {
    id: "mental-health-crisis",
    title: "Mental Health",
    description: "Mental health is our body's emotional, phycological, and social well-being. It can be heritable through one's family or created over life through various experiences and the effects of them can be very different for different individuals. Depression is the most common disorder affecting approximately 280 million people worldwide which correlates to 3.5% of the world population. The acknowledgement of mental health issues has been growing in recent years due to the fact that the quality of lives of many individuals have been compromised. In teens and young adults, The World Health Organization has declared suicide related to mental health to be the second leading cause of death.",
    imageUrl: require('./mental-health.jpg'),
    links: [
        {
            name: 'Charity links are currently being updated',
        }
    ]
};


const SHORELINE_HEALTH = {
    id: "shoreline-health",
    title: "Shoreline Health",
    description: "Shorelines have been severely disrespected for centuries, destroying the habitat of wildlife due to over use of resources. This is felt by the approximate 7 million Canadians who live in close proximity to the shoreline. Coastal erosion is responsible for roughly $500 million per year in coastal property loss within America, with similar impacts seen in Canada. In addition to property damage, coastal erosion also significantly and detrimentally impacts aquatic, terrestrial, and vegetative life. These effects in turn harm recreational activities such as fishing and swimming, and decrease aesthetic value. Salt and other chemical runoffs into freshwater can have devastating effects. Heavy metals and road salts accumulate in freshwater ecosystems, and both have been shown to be individually toxic to freshwater organisms and to affect ecosystem function and ecosystem services.",
    imageUrl: require('./shoreline-health.jpg'),
    links: [
        {
            name: 'Charity links are currently being updated',
        }
    ]
};

const LGBTQ2 = {
    id: "lgbtq2-discrimination",
    title: "LGBTQ2+ Discrimination",
    description: "Discrimination against those who identify differently than being heterosexual or cisgender has resulted in a variety of tragedies including suicide. Only in recent years has the governments of many countries created bills legalizing marriage between two same sex individuals. Before this, the freedom to express one’s identity without fear or repercussions was limited or not seen at all. By not being able to portray one’s true identity, peoples relationships with their families, friends, and other relationships were heavily affected. The lack of support in this manner caused people to fall into mental health issues which have resulted in suicide.",
    imageUrl: require('./lgbtq.jpg'),
    links: [
        {
            name: 'Charity links are currently being updated',
        }
    ]
};

export const CauseData = [
    COVID_19, BLACK_LIVES_MATTER, PALESTINE_HUMAN_RIGHTS, TURKEY_GENDER_VIOLENCE, UYGHUR_MUSLIMS, LGBTQ2, SHORELINE_HEALTH, MENTAL_HEALTH_CRISIS, TALIBAN_IN_AFGHANISTAN
];